<template>
  <base-section id="organization" class="white mt-n15" space="36">
    <v-container>
      <base-section-heading title="Beberapa media yang telah memuat berita sebagai berikut:" />
      <v-slide-group>
        <template v-for="data in organization">
          <v-slide-item :key="data.id" class="cursor align-self-center">
            <base-img
              :src="require(`@/assets/news/news-${data.id}.png`)"
              color="grey"
              contain
              height="100"
              width="128"
              alt=""
              @click="URL(data.link)"
            />
          </v-slide-item>

          <v-responsive
            :key="`divider-${data.id}`"
            class="text-center"
            height="56"
            width="48"
          >
            <v-divider vertical />
          </v-responsive>
        </template>
      </v-slide-group>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionOrganization",
  data: () => ({
    organization: [
      {
        id: 1,
        link: "https://wartaekonomi.co.id/read535920/orion-penjaminan-indonesia-mulai-operasional-dengan-izin-ojk-siap-dukung-pelaku-usaha",
      },
      {
        id: 2,
        link: "https://rri.co.id/jakarta/bisnis/716645/pt-orion-penjaminan-indonesia-kantongi-izin-operasional-ojk",
      },
      {
        id: 3,
        link: "https://infobanknews.com/kantongi-izin-ojk-orion-penjaminan-indonesia-siap-dukung-pelaku-usaha-kembangkan-bisnis/",
      },
      {
        id: 4,
        link: "https://ekonomi.republika.co.id/berita/se6mtk349/pt-orion-penjaminan-indonesia-resmi-beroperasi-dengan-izin-ojk",
      },
      {
        id: 5,
        link: "https://ekbis.sindonews.com/read/1386057/178/kantongi-izin-ojk-orion-jadi-pemain-baru-dalam-industri-penjaminan-1716991511",
      },
    ],
    // organization: []
  }),
  methods: {
    URL(id) {
      window.open(id, "_blank");
    },
  },
};
</script>

<style scoped>
.cursor {
  cursor: pointer;
}
</style>
